import React from "react";
import Banner from "../../components/banner/banner";
import Menu from "../../components/menu/menu";
import Menu_Second from "../../components/menu_second/menu_second";
import './home.css'

function Home() {

    return(
    <body>
        <Banner title="PORTFOLIO"/>
    </body>
    )
}

export default Home;