import React from "react";
import Banner from "../../components/banner/banner";
import './more.css'

function More() {

    return(
    <div className="more_parents">
        <Banner title="soon.."/>
    </div>
    )
}

export default More;